import dayjs from 'dayjs';
import { DateTimeHelper } from './datetime.helper';
class BITHelper {
  public static addCustomInfoToMemberBits( bitsList?: any[], loyaltyAccountsData?: any[]): any[] {
    const memberBitsWithCustomInfo: any[] = [];
    if (bitsList) {
      for (let index = 0; index < bitsList?.length; index++) {
        const referenceBIT = bitsList[index];
        let customBitInfo: any = bitsList[index];
        const systemDateFormat = referenceBIT.h_bit_date.includes('.')
          ? 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'
          : 'YYYY-MM-DDTHH:mm:ssZ';
        customBitInfo.rewardArray = [];
        customBitInfo.allActionsArray = [];

        customBitInfo = this.addCustomInfoToBITOfferActions( referenceBIT.offer_actions, bitsList[index], customBitInfo, loyaltyAccountsData);
        customBitInfo = this.addPointsInfoToBIT( referenceBIT.loyalty_balances, customBitInfo, loyaltyAccountsData);

        if (referenceBIT.loyalty_balances) {
          customBitInfo.balancesArray = [];
          for ( let balancesIndex = 0; balancesIndex < referenceBIT.loyalty_balances.length; balancesIndex++ ) {
            const loyaltyBalItem = referenceBIT.loyalty_balances[balancesIndex];
            if (loyaltyAccountsData) {
              const accountIndex = loyaltyAccountsData.findIndex( (account: any) => account.id == loyaltyBalItem.loyalty_account_id );
              if (accountIndex !== -1 && loyaltyBalItem.rewarded !== 0) {
                customBitInfo['balancesArray'].push({
                  balance                 :   loyaltyBalItem.rewarded,
                  loyalty_account_symbol  :   loyaltyAccountsData[accountIndex].logo,
                  loyalty_account_id      :   loyaltyBalItem.loyalty_account_id,
                  loyalty_account_name    :   loyaltyAccountsData[accountIndex].loyalty_currency,
                });
              }
            }
          }
        }
        if (referenceBIT.h_bit_category == 'SERVICE') {
          customBitInfo.offer_name = referenceBIT.header.h_bit_type_name + ' Service';
        }
        memberBitsWithCustomInfo.push(customBitInfo);
      }
    }
    return memberBitsWithCustomInfo;
  }

  public static addCustomInfoToBITOfferActions( offerActions: any[], bit: any, customBit: any, loyaltyAccountsData?: any[]): any {
    let updatedCustomBit = customBit;
    if (offerActions && offerActions.length > 0) {
      updatedCustomBit.privilege_name = '';
      for (let index = 0; index < offerActions.length; index++) {
        const offerActionItem = offerActions[index];
        updatedCustomBit = this.updateInfoAsPerOfferActionType( offerActionItem, bit, updatedCustomBit, loyaltyAccountsData );
        if ( bit.h_bit_category === 'ENROLLMENT' || bit.h_bit_category === 'DEAL_REDEMPTION') {
          updatedCustomBit.offer_name = bit.offer_actions[0].offer_name;
        }
      }
      if (updatedCustomBit.allActionsArray) {
        const maxValue = Math.max.apply( Math, updatedCustomBit.allActionsArray.map((offer: { value_n: any; }) => { return offer.value_n; }) );
        const maxValueIndex = bit.offer_actions.findIndex( (offerActionItem: { value_n: number; type: string; }) => offerActionItem.value_n === maxValue && offerActionItem.type === 'REWARD_POINTS' );
        if (maxValueIndex > -1) {
          updatedCustomBit.offer_name = bit.offer_actions[maxValueIndex].offer_name;
        }
        else {
          if ( bit.offer_actions[0].type !== 'UPDATE_ATTRIBUTE' && bit.offer_actions[0].type !== 'REWARD_PRIVILEGE' ) {
            updatedCustomBit.offer_name = bit.offer_actions[0].offer_name;
          }
        }
      }
      if (loyaltyAccountsData) {
        for ( let accountIndex = 0; accountIndex < loyaltyAccountsData.length; accountIndex++ ) {
          const accountItem = loyaltyAccountsData[accountIndex];

          if ( updatedCustomBit.rewardArrayCurrency == accountItem.loyalty_currency ) {
            updatedCustomBit.rewardArrayCurrency_symbol = accountItem.logo ?? undefined;
          }
        }
      }
    } else {
      // TODO : Update this logic once we get confirmation from DJANGO
      // TODO : Refer legacy --> commonservice.js --> line 905
      // if (bit.header.h_offer_name) && bit.header.h_offer_name !== '') {
      //   bit.offer_name = bit.header.h_offer_name;
      // }
      updatedCustomBit.rewardArrayCurrency_symbol = '';
    }
    return updatedCustomBit;
  }

  public static updateInfoAsPerOfferActionType( offerAction: any, bit: any, customBitInfo: any, loyaltyAccountsData?: any[]): any {
    switch (offerAction.type) {
      case 'REWARD_POINTS':
      case 'REDEEM_POINTS':
        if (customBitInfo.rewardArray?.length !== 0) {
          let rewardArrayIndex = customBitInfo.rewardArray?.findIndex( (rewardItem: any) => rewardItem.subject === offerAction.subject );
          if ( rewardArrayIndex !== undefined && rewardArrayIndex > -1 && customBitInfo.rewardArray ) {
            customBitInfo.rewardArray.push({
              value_n             :   offerAction.value_n,
              subject_name        :   offerAction.subject_name,
              subject             :   offerAction.subject,
              type                :   offerAction.type,
              rewardArrayCurrency :   offerAction.subject_name,
            });
            customBitInfo.rewardArrayCurrency = customBitInfo.rewardArray[0].subject_name;
          }
          if (customBitInfo.allActionsArray) {
            customBitInfo.allActionsArray.push({
              value_n             :   offerAction.value_n,
              subject_name        :   offerAction.subject_name,
              subject             :   offerAction.subject,
              type                :   offerAction.type,
              rewardArrayCurrency :   offerAction.subject_name,
            });
          }
        } else {
          if (loyaltyAccountsData) {
            for ( let accountIndex = 0; accountIndex < loyaltyAccountsData.length; accountIndex++ ) {
              const accountItem = loyaltyAccountsData[accountIndex];
              if (offerAction.subject == accountItem.id) {
                customBitInfo.loyalty_account_type = accountItem.currency_type;
              }
            }
          }
          if (customBitInfo.loyalty_account_type === 'Redemption') {
            customBitInfo.rewardArray.push({
              value_n             :   offerAction.value_n,
              subject_name        :   offerAction.subject_name,
              subject             :   offerAction.subject,
              type                :   offerAction.type,
              rewardArrayCurrency :   offerAction.subject_name,
            });
            if (customBitInfo.allActionsArray) {
              customBitInfo.allActionsArray.push({
                value_n             :   offerAction.value_n,
                subject_name        :   offerAction.subject_name,
                subject             :   offerAction.subject,
                type                :   offerAction.type,
                rewardArrayCurrency :   offerAction.subject_name,
              });
            }
            customBitInfo.rewardArrayCurrency = customBitInfo.rewardArray[0].subject_name;
          } else if (customBitInfo.allActionsArray) {
            customBitInfo.allActionsArray.push({
              value_n             :   offerAction.value_n,
              subject_name        :   offerAction.subject_name,
              subject             :   offerAction.subject,
              type                :   offerAction.type,
              rewardArrayCurrency :   offerAction.subject_name,
            });
          }
        }
        customBitInfo.rewardArraySum = this.reduceList( customBitInfo.rewardArray );
        break;

      case 'REWARD_PRIVILEGE':
        customBitInfo.isRewardPrivilge = true;
        customBitInfo.privilegeName = offerAction.subject_name;
        //We are showing first offer that got applied for the bit.
        customBitInfo.offer_name = bit.offer_actions[0].offer_name;
        customBitInfo.privilegeId = offerAction.subject;
        customBitInfo['total_privilege_count'] = ( customBitInfo['total_privilege_count'] ?? 0 ) + offerAction.privilege_quantity;
        let redeemPointsindex = bit.offer_actions.findIndex( (offerActionItem: any) => offerActionItem.type === 'REDEEM_POINTS' );
        if (redeemPointsindex !== -1) {
          customBitInfo.offer_name = bit.offer_actions[redeemPointsindex].offer_name;
        }
        else {
          let isrewardPrivIndex = bit.offer_actions.findIndex( (offerActionItem: any) => offerActionItem.type === 'REWARD_PRIVILEGE' );
          if (isrewardPrivIndex !== -1) {
            customBitInfo.offer_name = bit.offer_actions[isrewardPrivIndex].offer_name;
          }
          else {
            customBitInfo.offer_name = bit.offer_actions[0].offer_name;
          }
        }
        break;

      case 'TRANSFER_POINTS':
        if (customBitInfo.rewardArraySum === 0) {
          customBitInfo.rewardArraySum = offerAction.value_n;
          if (offerAction.transfer_action === 'SEND') {
            customBitInfo.rewardArraySum = -Math.abs( customBitInfo.rewardArraySum );
          }
          loyaltyAccountsData?.forEach((accountItem: any) => {
            if (accountItem.id === offerAction.subject) {
              customBitInfo.rewardArrayCurrency = accountItem.loyalty_currency;
            }
          });
        }
        break;

      case 'TRANSFER_PRIVILEGE':
        customBitInfo.offer_name = bit.offer_actions[0].offer_name;
        customBitInfo.privilegeId = offerAction.subject;
        customBitInfo['total_privilege_count'] = customBitInfo['total_privilege_count'] ?? 0 + offerAction.privilege_quantity;
        break;

      case 'UPDATE_ATTRIBUTE':
        let redeemPointsIndex = bit.offer_actions.findIndex( (offerActionItem: any) => offerActionItem.type === 'REDEEM_POINTS' );
        if (redeemPointsIndex !== -1) {
          customBitInfo.offer_name = bit.offer_actions[redeemPointsIndex].offer_name;
        }
        else {
          let isrewardPrivIndex = bit.offer_actions.findIndex( (offerActionItem: any) => offerActionItem.type === 'REWARD_PRIVILEGE' );
          if (isrewardPrivIndex !== -1) {
            customBitInfo.offer_name = bit.offer_actions[isrewardPrivIndex].offer_name;
          }
          else {
            customBitInfo.offer_name = bit.offer_actions[0].offer_name;
          }
        }
        break;
    }
    return customBitInfo;
  }

  public static reduceList(listOfItems: any[] | undefined): number {
    let val: number = 0;
    if (listOfItems) {
      for (let i = 0; i < listOfItems.length; i++) {
        if (listOfItems[i].type === 'REWARD_POINTS') {
          val = val + listOfItems[i].value_n;
        } else {
          val = val - listOfItems[i].value_n;
        }
      }
    }
    return val;
  }

  // Adding the points info to the BIT
  public static addPointsInfoToBIT(
    loyaltyBalancesArray: any[],
    customBit: any,
    loyaltyAccountsData?: any[]
  ): any {
    customBit.loyaltyPointsArray = [];
    customBit.highestPointsAccount = {};
    if (
      ( (customBit.points_redeemed || customBit.points_rewarded) &&
      loyaltyBalancesArray?.length > 0) &&
      loyaltyAccountsData &&
      loyaltyAccountsData.length > 0
    ) {
      let pointsModificationActions = [ ...(customBit.offer_actions ?? [] ), ...(customBit.tier_actions ?? []) ]?.filter( (action) => ( action.type === 'REWARD_POINTS' || action.type === 'REDEEM_POINTS' || action.type === 'TRANSFER_POINTS' ) );
      // Adding the points to the points array
      for (let index = 0; index < loyaltyBalancesArray?.length; index++) {
        let loyaltyAccountIndex = loyaltyAccountsData.findIndex(
          (account) => account.id === parseInt(loyaltyBalancesArray[index].loyalty_account_id)
        );
        if (loyaltyAccountIndex !== -1 &&  (pointsModificationActions.some( (account) =>{ return account.subject === loyaltyBalancesArray[index].loyalty_account_id } ) ) ) {
          let totalPoints = loyaltyBalancesArray[index].rewarded - loyaltyBalancesArray[index].redeemed;
          let pointsArrayElement = {
            balance             :   totalPoints,
            logo                :   loyaltyAccountsData[loyaltyAccountIndex].logo,
            loyalty_account_id  :   loyaltyBalancesArray[index].loyalty_account_id,
            name                :   loyaltyAccountsData[loyaltyAccountIndex].loyalty_currency,
            account_type        :   loyaltyAccountsData[loyaltyAccountIndex].currency_type,
          };
          customBit.loyaltyPointsArray.push(pointsArrayElement);
        }
      }
      // Sorting the points array based on the balance
      customBit.highestPointsAccount = customBit.loyaltyPointsArray.sort(
        (a: any, b: any) => {
          if (
            (b.account_type === 'Redemption' && a.account_type !== 'Redemption') ||
            (b.account_type === 'Redemption' && b.balance > a.balance) ||
            (b.account_type === 'Recognition' && a.account_type === 'Recognition' && b.balance > a.balance) ||
            (b.account_type === 'Recognition' && a.account_type === 'External') ||
            (b.account_type === 'External' && a.account_type === 'External' && b.balance > a.balance)
          ) {
            return 1;
          }
          return -1;
        }
      )[0];
    }
    if( customBit.highestPointsAccount && customBit.highestPointsAccount.name !== undefined && customBit.highestPointsAccount.name !== null && customBit.highestPointsAccount.name !== '' && Math.abs(customBit.highestPointsAccount.balance) >= 0){
      let avatarText = '';
      const words = customBit.highestPointsAccount.name.split(' ');
      if (words[0]) {
        avatarText += words[0][0] ?? '';
      }
      if (words[1]) {
        avatarText += words[1][0] ?? '';
      }
      customBit.highestPointsAccount.avatarText = avatarText ;
    }

    if(customBit?.highestPointsAccount?.balance === 0 && (customBit?.highestPointsAccount?.logo === '' || customBit?.highestPointsAccount?.logo === undefined || customBit?.highestPointsAccount?.logo === null)){
      console.warn('possible issue with the logo of the highest points account : ', customBit.highestPointsAccount?.name );
    }
    return customBit;
  }
}

export { BITHelper };
